import React, { Component } from "react";
import "./App.css";

class App extends Component {
  render() {
    return <div id="breath-light" />;
  }
}

export default App;
